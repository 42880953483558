
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { Plan, Team } from '@/types/team'
import { TeamRole } from '@/types/membership'
import AppDre from '@common/AppDre.vue'

export default defineComponent({
  name: 'Subscriptions',
  components: {
    AppDre
  },
  setup() {
    const store = useStore()
    const userIsAdmin = computed(() => store.getters['user/getActiveTeamRole'] !== TeamRole.Member)
    const activeTeam = computed<Team>(() => store.getters['teams/getActiveTeam'])
    const activeMembers = computed<number>(() => store.getters['teams/getActiveTeamMembers'].length)

    return {
      userIsAdmin,
      activeTeam,
      activeMembers,
      Plan
    }
  }
})
